import React from 'react';
import MedycynaEstetyczna from '../../components/Uslugi/MedycynaEstetyczna';
import Layout from '../../layout/pl';

const MedycynaEstetycznaComp = (props) => {
	return (
		<Layout {...props}>
			<MedycynaEstetyczna />
		</Layout>
	);
};

export default MedycynaEstetycznaComp;
