import React from 'react';
import TitleHeader from '../TitleHeader';
import { FormattedMessage } from 'react-intl';


const MedycynaEstetyczna = () => {
	return (
		<div>
			<TitleHeader title={<FormattedMessage id="menu.services.aestheticMedicine" />} />

<FormattedMessage id="brak.aliasu"/>
			<div className="grid-two-column content">
				<h2 className="first-column">WYPEŁNIANIE KWASEM HIALURONOWYM</h2>
				<p className="second-column">
					Kwas hialuronowy występuje w skórze w sposób naturalny - jest podstawowym składnikiem skóry, który
					utrzymuje jej właściwy poziom nawilżenia i jędrności. Niestety wraz z wiekiem jego ilość stopniowo
					się zmniejsza, wskutek czego skóra wysusza się, wiotczeje, pojawiają się zmarszczki, a policzki i
					usta tracą swoją objętość. Preparaty zawierające kwas hialuronowy wstrzyknięte do skóry właściwej
					skutecznie uzupełniają jego niedobór, nawilżają skórę, wypełniają zmarszczki, nadają objętość,
					modelują usta, poprawiają kontur twarzy, pobudzają naturalne procesy regeneracyjne skóry, dzięki
					czemu nawet po wchłonięciu preparatu skóra wygląda lepiej niż przed zabiegiem.
				</p>
				<h2 className="first-column">TOKSYNA BOTULINOWA</h2>
				<p className="second-column">
					jest bardzo silnie działającą naturalną toksyną powodującą zahamowanie uwalniania acetylocholiny -
					neuroprzekaźnika odpowiedzialnego za przekaźnictwo impulsów między nerwami a mięśniami. Toksyna
					botulinowa działa poprzez blokowanie połączeń między zakończeniami nerwów, a unerwianymi przez nie
					mięśniami, czyli blokuje tzw. synapsy nerwowo - mięśniowe. Nie dochodzi wtedy do skurczów porażonego
					mięśnia. Botulina jest równie skuteczna u mężczyzn jak i u kobiet.
				</p>
				<h2 className="first-column">LASER FRAKCYJNY EMERGE</h2>
				<p className="second-column">
					Jeśli walczysz bez sukcesu z problemem blizn lub rozstępów, chcesz wyrównać koloryt skóry lub
					przywrócić jej młodszy wygląd, dowiedz się więcej na temat lasera frakcyjnego Emerge™, amerykańskiej
					firmy Palomar.
				</p>
				<h2 className="first-column">INTRALIPOTERAPIA</h2>
				<p className="second-column">
					Likwidowanie tkanki tłuszczowej (lipoliza iniekcyjna) to nic innego jak nieinwazyjne modelowanie
					sylwetki, polegające na podaniu zastrzyków z substancji powodującej rozpuszczanie komórek
					tłuszczowych. Aqualyx to preparat stosowany jako alternatywa chirurgicznej liposukcji. Jest to na
					dzień dzisiejszy jedyny na świecie, zarejestrowany preparat do zabiegów iniekcyjnego usuwania tkanki
					tłuszczowej, a technika intralipoterapii została uznana za najbezpieczniejszą technikę iniekcyjną w
					redukcji tkanki tłuszczowej.
				</p>
				<h2 className="first-column">MEZOTERAPIA IGŁOWA</h2>
				<p className="second-column">
					Mezoterapia igłowa to doskonały sposób na poprawę jakości skóry, polegający na dostarczeniu
					bezpośrednio do skóry substancji leczniczych, regenerujących lub odżywczych, Jest to metoda leczenia
					i rewitalizacji skóry wykorzystywana w medycynie estetycznej od kilkudziesięciu lat. Jest to zabieg
					naturalny , w pełni bezpieczny i skuteczny.
				</p>
				<h2 className="first-column">MEZOTERAPIA WŁOSÓW</h2>
				<p className="second-column">
					Mezoterapia igłowa jest jednym ze skuteczniejszych sposobów walki z wypadaniem włosów. Mężczyźni z
					reguły łysieją częściej i szybciej, ale to leczenie łysienia u kobiet jest bardziej skomplikowane.
					Po określeniu przyczyny nadmiernego wypadania włosów, należy szybko wdrożyć metodę leczenia.
					Mezoterapia igłowa, dzięki wielu nakłuciom, pozwala na wprowadzenie w skórę odżywczych preparatów.
					Pobudza również "uśpione" cebulki włosów.
				</p>
				<h2 className="first-column">DERMAPEN</h2>
				<p className="second-column">
					Dermapen jest nowoczesnym urządzeniem medycznym, które pozwala na wykonanie zabiegów anti-aging,
					regeneracyjnych, rozjaśniających i leczniczych na skórze twarzy, ciała i głowy. Dzięki zabiegowi
					DERMAPEN™ widoczne jest spłycenie zmarszczek, efekt pogrubienia skóry oraz naturalna stymulacja
					produkcji kolagenu.Ponadto zmniejszenie widoczności porów, redukcja widoczności rozstępów czy blizn
					potrądzikowych i blizn oparzeniowych. Urządzenie stosowane jest także w leczeniu łysienia.
				</p>

				{/* <h2 className="first-column">Jeszcze piękniejsza</h2>
                				<p className="second-column">
                					Doskonałym uzupełnieniem do zabiegów stomatologii estetycznej są zabiegi z zakresu medycyny
                					estetycznej oraz anty-aging. Dzięki holistycznemu podejściu do projektowania uśmiechu możemy sobie
                					pozwolić nie tylko na pełnowymiarową estetykę twarzy ale również komfort, spójność i bezpieczeństwo
                					w planowaniu procedur. Zabiegi medycyny estetycznej oprócz bezsprzecznie widocznych efektów
                					wizualnych obejmują także: leczenie bruksizmu, wspomaganie efektów ortodontycznych i działają jak
                					głęboko-wchłanialny zastrzyk pewności siebie.
                				</p>
                				<h2 className="first-column">Laser frakcyjny EMERGE</h2>
                				<p className="second-column">
                					Jeśli walczysz bez sukcesu z problemem <span className="brown bold">blizn</span> lub{' '}
                					<span className="brown bold">rozstępów</span>, chcesz{' '}
                					<span className="brown bold">wyrównać koloryt</span> skóry lub przywrócić jej{' '}
                					<span className="brown bold">młodszy wygląd</span>, dowiedz się więcej na temat lasera frakcyjnego
                					Emerge™, amerykańskiej firmy Palomar. Emerge™ to laser należący do frakcyjnych laserów
                					nieablacyjnych. Oznacza to, że działa on na skórę <span className="brown bold">wybiórczo</span>, nie
                					naruszając przy tym jej ciągłości. Pomiędzy wybranymi fragmentami uszkodzonej skóry pozostawione
                					zostają części zdrowej, nienaświetlonej tkanki, co znacznie przyspiesza naturalne procesy
                					regeneracji oraz syntezy włókien kolagenu i elastyny. Emerge przeznaczony jest do resurfacingu
                					(odmładzania skóry), usuwania i redukcji powierzchownych niedoskonałości. Emerge™ posiada{' '}
                					<span className="brown bold">certyfikat FDA</span> potwierdzający redukcję zmarszczek wokół oczu,
                					tzw. kurzych łapek. Emerge sprawia, że skóra jest bardziej gęsta i jędrna oraz wygładzona, a jej
                					powierzchowne niedoskonałości znikają czasem już po pierwszym zabiegu.
                				</p> */}
			</div>
		</div>
	);
};
export default MedycynaEstetyczna;
